import React from 'react';

const CST231 = () => {
    return (
        <div>
            <h2>CST 231: Problem Solving and Programming C++</h2>
            <p>
                This is an introductory programming course to develop problem-solving techniques for numerical and
                non-numerical problems from various disciplines. Students will design the solution to each problem and
                implement it in the C++ programming language. Coverage includes introduction to computer system, C++
                basics, flow control, functions, I/O streams, arrays, strings, and classes.
            </p>
        </div>
    )
};

export default CST231;