import React from 'react';

const CST438 = () => {
    return (
        <div>
            <h2>CST 438: Software Engineering</h2>
            <p>
                Prepares students for large-scale software development using software engineering principles and
                techniques. Coverage includes software process, requirements analysis and specification, software
                design, implementation, testing, and project management. Students are expected to work in teams to carry
                out a realistic software project.
            </p>
            <div>
                <h2>Final Deliverable: Ride Share Crime Reporting Tool</h2>
                <div>
                    <a href={"https://github.com/DustinFehlman/CST438FinaProject"}>View on Github</a>
                </div>
            </div>
        </div>
    )
};

export default CST438;