import React from 'react';

const CST338 = () => {
    return (
        <div>
            <h2>CST 338: Software Design</h2>
            <p>
                Provides students with the fundamental concepts to develop large-scale software, focusing on the
                object-oriented programming techniques. Coverage includes the introduction to Java programming language,
                object-oriented programming, software life cycle and development processes, requirements analysis, and
                graphical user interface development.
            </p>
            <div>
                <h2>Final Deliverable: Class Assignments</h2>
                <div>
                    <a href={"https://github.com/DustinFehlman/CST338"}>View on Github</a>
                </div>
            </div>
        </div>
    )
};

export default CST338;