import React from 'react';

const CST336 = () => {
    return (
        <div>
            <h2>CST 336: Internet Programming</h2>
            <p>
                Provides students with dynamic web application development skills, focusing on the integration of
                server-side programming, database connectivity, and client-side scripting. Coverage includes the
                Internet architecture, responsive design, RESTful web services, and Web APIs.
            </p>
            <div>
                <h2>Final Deliverable: Shopping Web Application</h2>
                <div>
                    <a href={"https://github.com/DustinFehlman/cst336/tree/master/cst336Final"}>View on Github</a>
                </div>
            </div>
        </div>
    )
};

export default CST336;