import React from 'react';

const CST238 = () => {
    return (
        <div>
            <h2>CST 238: Introduction to Data Structures</h2>
            <p>
                Provides students with intermediate-level programming skills to make it possible for them to write
                clear, efficient, and high-quality code. Students learn basic data structures such as array, list,
                stack, queue, and tree. They also acquire several basic algorithms in programming such as searching,
                sorting, and recursion. Furthermore, students learn in-depth knowledge of C++ programming language such
                as classes, pointers, and dynamic memory management.
            </p>
        </div>
    )
};

export default CST238;