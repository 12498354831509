import React from 'react';

const CST499 = () => {
    return (
        <div className={"subContent"}>
            <h2>CST 499: Computer Science Capstone</h2>
            <p>
                Students will work on a project in large groups (up to 5 students in each group), developing
                requirements specification, a solution plan followed by design and implementation of the solution. The
                problem statement for the projects will be selected by the faculty. Faculty will also play the role of a
                project manager directing the schedule and deliverables for these projects.
            </p>
            <div>
                <h2>Final Deliverable One: Capstone Proposal - ShotsOrNot</h2>
                <div>
                    <iframe
                        src="https://docs.google.com/document/d/e/2PACX-1vTZMsEpzfXnWSFqeB2pA1UFa5pkiqxeUZNDsLGrfkVtIl8UyxIEyv9IkRZfRMC61ogDZu36KNbZj1yV/pub?embedded=true"
                        frameBorder="0" width="900" height="500" allowFullScreen="true" mozallowfullscreen="true"
                        webkitallowfullscreen="true"></iframe>
                </div>
            </div>
            <div>
                <h2>Final Deliverable Two: Capstone Report</h2>
                <div>
                    <iframe frameBorder="0" width="900" height="500" allowFullScreen="true" mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                            src="https://docs.google.com/document/d/e/2PACX-1vSa2cCCMTmQJw2o1sJZhlPUEXCuM2MVBzBKmMgYwAHhGPVhyVT1bFG7vXUZHpoGwZof6lpNd8s9BknU/pub?embedded=true"></iframe>
                </div>
            </div>
            <div>
                <h2>Final Deliverable Three: Capstone Project Video</h2>
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ci7iGrn6Daw" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
};

export default CST499;