import React, {useState} from 'react';
import Home from './pages/home/Home';
import CoreMlo from './pages/core-mlo/CoreMlo';
import ConcentrationMlo from './pages/concentration-mlo/ConcentrationMlo';
import FinalMlo from './pages/final-mlo/FinalMlo';

function App() {
    const [activePage, setActivePage] = useState('home');
    const [childPage, setChildPage] = useState('main');
    return (
        <div className="App">
            <header className="App-header">
                <h1>DUSTIN FEHLMAN</h1>
                <nav>
                    <ul>
                        <li>
                            <button className={"navButton"} onClick={() => setActivePage('home')}>HOME</button>
                        </li>
                        <li>
                            <button className={"navButton"} onClick={() => {
                                setActivePage('coreMlo');
                                setChildPage('main')
                            }}>CORE MLO
                            </button>
                        </li>
                        <li>
                            <button className={"navButton"} onClick={() => {
                                setActivePage('concentrationMlo');
                                setChildPage('main')
                            }}>CONCENTRATION MLO
                            </button>
                        </li>
                        <li>
                            <button className={"navButton"} onClick={() => {
                                setActivePage('finalMlo');
                                setChildPage('main')
                            }}>FINAL MLO
                            </button>
                        </li>
                    </ul>
                </nav>
            </header>
            <div id={"mainContent"}>
                {activePage === 'home' && <Home/>}
                {activePage === 'concentrationMlo' && <ConcentrationMlo page={childPage} setChildPage={setChildPage}/>}
                {activePage === 'finalMlo' && <FinalMlo page={childPage} setChildPage={setChildPage}/>}
                {activePage === 'coreMlo' && <CoreMlo page={childPage} setChildPage={setChildPage}/>}
            </div>
        </div>
    );
}

export default App;
