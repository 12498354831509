import React from 'react';

const MATH151 = () => {
    return (
        <div>
            <h2>MATH 151: Calculus II</h2>
            <p>
                Includes the calculus of exponential and logarithmic functions, trigonometric and inverse trigonometric
                functions, techniques of integration, separable differential equations, Taylor polynomials, L'Hopital's
                rule, improper integrals, series, and introduction to partial derivatives.
            </p>
        </div>
    )
};

export default MATH151;