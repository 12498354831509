import React from 'react';

const MATH150 = () => {
    return (
        <div>
            <h2>MATH 150: Calculus I</h2>
            <p>
                Includes limits, continuity, derivatives including trigonometric functions, chain rule, curve sketching,
                extremum problems, implicit differentiation, related rates, Mean Value Theorem, logarithmic and
                trigonometric functions, introduction to integration, fundamental theorem of calculus, substitution, and
                applications.
            </p>
        </div>
    )
};

export default MATH150;