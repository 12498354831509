import React from 'react';

const CST363 = () => {
    return (
        <div>
            <h2>CST 363: Introduction to Database Systems</h2>
            <p>
                This course provides balanced coverage of database use and design,focusing on relational databases.
                Students will learn to design relational schemas, write SQL queries, access a DB programmatically,and
                perform database administration. Students will gain a working knowledge of the algorithms and data
                structures used in query evaluation and transaction processing. Students will also learn to apply newer
                database technologies such as XML, NoSQL, and Hadoop.
            </p>
        </div>
    )
};

export default CST363;