import React, {useState} from 'react';

export default function Home() {
    return (
        <div className={"subMain"}>
            <div className={"subContent"}>
                <div>
                    <h2 className={"subHeader"}>Software Engineer</h2>
                </div>
                <div>
                    <p>Dynamic and detail-oriented Full-Stack Software Engineer with 2+ years and increasing experience
                        and
                        10+
                        years of broad-based experience in customer service and retail. Highly skilled and experienced
                        in
                        planning and delivering software platforms used across multiple products and organizational
                        units.
                        Deep
                        expertise and hands-on experience with Web Applications and programming languages such as Java,
                        Python,
                        PHP, JavaScript, C++, Bash, SQL, CSS [CSS3, SASS], and HTML. A proven innovator who continuously
                        develops and designs applications beneficial for multiple processes within the organization.
                        Excellent
                        knowledge in all phases of multi-platform, multi-site infrastructure, application development,
                        technical
                        process improvement, and system integration. Skilled web developer with a passion for web
                        applications
                        and success in managing projects. Equipped with excellent work ethic, and possesses a strong
                        sense
                        of
                        leadership with a commendable record of dependability combined with integrity.

                        By completing my Computer Science degree, I will be able to further prove my development
                        experience.
                    </p>
                    <ul>
                        <li>Email: dustin.c.fehlman@gmail.com</li>
                        <li>LinkedIn: <a
                            href={"https://www.linkedin.com/in/dustinfehlman"}>https://www.linkedin.com/in/dustinfehlman</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"subContent"}>
                <h2 className={"subHeader"}>Computer Science B.S. at California State University, Monterey Bay <img
                    id={"csumbLogo"} src={"\\media\\CSUMBLogo.png"}/>
                </h2>
                <p>In the CS program, students learn fundamental computer science concepts while applying their
                    knowledge to
                    solve real-world problems. Collaboration, team work and communication skills are infused into the
                    learning experiences with heavily project-based courses in a state-of-the-art technological
                    environment.

                    Students begin by taking a set of common core courses to establish a strong foundation in computer
                    science, followed by taking courses in one of four concentration areas: software engineering,
                    network
                    and security, data science, or game development. The curriculum provides students the opportunity to
                    learn concepts and develop skills in more than one concentration, helping them to become well
                    rounded
                    computer science professionals.</p>
            </div>
        </div>
    )
}