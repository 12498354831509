import React from 'react';
import CoreMloMain from "./CoreMloMain";
import CST300 from "./CST300";
import CST231 from "./CST231";
import CST238 from "./CST238";
import MATH170 from "./MATH170";
import MATH130 from "./MATH130";
import CST370 from "./CST370";
import MATH150 from "./MATH150";
import MATH151 from "./MATH151";
import CST237 from "./CST237";
import CST205 from "./CST205";

export default function CoreMlo(props) {
    return(
        <div>
            {props.page === "main" && <div><CoreMloMain setChildPage={props.setChildPage}/></div>}
            {props.page === "CST300" && <div><CST300/></div>}
            {props.page === "CST231" && <div><CST231/></div>}
            {props.page === "CST238" && <div><CST238/></div>}
            {props.page === "MATH170" && <div><MATH170/></div>}
            {props.page === "MATH130" && <div><MATH130/></div>}
            {props.page === "CST370" && <div><CST370/></div>}
            {props.page === "MATH150" && <div><MATH150/></div>}
            {props.page === "MATH151" && <div><MATH151/></div>}
            {props.page === "CST237" && <div><CST237/></div>}
            {props.page === "CST205" && <div><CST205/></div>}
        </div>
    )
}