import React from 'react';

import FinalMloMain from "./FinalMloMain";
import CST361 from "./CST361";
import CST234 from "./CST234";
import CST311 from "./CST311";
import CST363 from "./CST363";
import CST325 from "./CST325";
import CST499 from "./CST499";

export default function FinalMlo(props) {
    return (
        <div>
            {props.page === "main" && <div><FinalMloMain setChildPage={props.setChildPage}/></div>}
            {props.page === "CST361" && <div><CST361/></div>}
            {props.page === "CST234" && <div><CST234/></div>}
            {props.page === "CST311" && <div><CST311/></div>}
            {props.page === "CST363" && <div><CST363/></div>}
            {props.page === "CST325" && <div><CST325/></div>}
            {props.page === "CST499" && <div><CST499/></div>}
        </div>
    )
}
