import React from 'react';

const CST300 = () => {
    return (
        <div>
            <h2>CST 300: Major ProSeminar</h2>
            <p>
                Helps students identify and articulate personal, professional, and social goals. Provides an integrated
                overview of the computer science and communication design majors and their requirements. Students
                develop a plan for their learning goals. Students learn writing, presentation, research and
                critical-thinking skills within the diversified fields of information technology and communication
                design. Students learn how to analyze, predict, and articulate trends in the academic, public
                service,and private enterprise related to their majors. Students write
                two major papers, generate a preliminary capstone idea and give a combination oral/multimedia
                presentation before both a small and large audience.
            </p>
            <div>
                <h2>Final Deliverable: Ethics Paper on Human Genetic Engineering</h2>
                <div>
                    <iframe width={"900"} height={"400"} src="https://docs.google.com/document/d/e/2PACX-1vS0Cw6jmjTz7P_c1Q0DTeBy1Mg_tFW0SWu56S6xLHzrFXeeX0ppfvIdHwxA3DILXX9WUISdDKOO2ebL/pub?embedded=true">
                    </iframe>
                </div>
            </div>
        </div>
    )
};

export default CST300;