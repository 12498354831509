import React from 'react';

const CST205 = () => {
    return (
        <div>
            <h2>CST 205: Multimedia Design and Programming</h2>
            <p>
                Introduces design, creation, and manipulation of interactive applications and electronic media for
                communication purpose. Focuses on creating media, understanding media concepts, and manipulating the
                created media using basic programming concepts of control flow, functions, expressions and data types in
                the Python language. Students acquire a basic understanding for digital media formats, how to design,
                create such media using basic programming skills.
            </p>
            <div>
                <h2>Final Deliverable: Game Creation and Presentation</h2>
                <div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jt8loHmVE40" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
};

export default CST205;