import React from 'react';

import ConcentrationMloMain from "./ConcentrationMloMain";
import CST338 from "./CST338";
import CST336 from "./CST336";
import CST438 from "./CST438";

export default function ConcentrationMlo(props) {
    return (
        <div>
            {props.page === "main" && <div><ConcentrationMloMain setChildPage={props.setChildPage}/></div>}
            {props.page === "CST338" && <div><CST338/></div>}
            {props.page === "CST336" && <div><CST336/></div>}
            {props.page === "CST438" && <div><CST438/></div>}
        </div>
    )
}
