import React from 'react';

const ConcentrationMloMain = (props) => {
    return (
        <div className={"subMain"}>
            <div className={"subContent"}>
                <h2 className={"subHeader"}>Concentration Major Learning Outcomes</h2>
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>Advanced Programming</th>
                            <th>Specialized Programming</th>
                            <th>Planning, Analysis, and Production of Software Applications</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST338")}>CST 338: Software Design
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST336")}>CST 336: Internet
                                            Programming
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST438")}>CST 438: Software
                                            Engineering
                                        </button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default ConcentrationMloMain;