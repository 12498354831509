import React from 'react';

const CST370 = () => {
    return (
        <div>
            <h2>CST 370: Design and Analysis of Algorithms</h2>
            <p>
                Students learn important data structures in computer science and acquire fundamental algorithm design
                techniques to get the efficient solutions to several computing problems from various disciplines. Topics
                include the analysis of algorithm efficiency, hash, heap, graph, tree, sorting and searching, brute
                force, divide-and-conquer, decrease-and-conquer, transform-and-conquer, dynamic programming, and greedy
                programming.
            </p>
            <div>
                <h2>Final Assignment</h2>
                <div>
                    <iframe
                        src="https://docs.google.com/document/d/e/2PACX-1vT_98q-Q1SRWRRx5WCHv4yG-KwMxt33EGEF-LZw1l1HoGG0nn-UpJbLuNKK9He1juLUCnXfqzmmtoDo/pub?embedded=true"
                        frameBorder="0" width="900" height="500" allowFullScreen="true" mozallowfullscreen="true"
                        webkitallowfullscreen="true"></iframe>
                </div>
            </div>
        </div>

    )
};

export default CST370;