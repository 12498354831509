import React from 'react';

const CST325 = () => {
    return (
        <div>
            <h2>CST 325: Graphics Programming</h2>
            <p>
                This course teaches the students the fundamentals of game programming and skills needed for game
                development, including GPU programming, matrix and quaternion algebra for physics calculation,
                animation, lighting and basics of implementing 3D models into a framework.
            </p>
            <div>
                <h2>All Course Work</h2>
                <div>
                    <a href={"https://github.com/DustinFehlman/CST325"}>View on Github</a>
                </div>
            </div>
        </div>
    )
};

export default CST325;