import React from 'react';

const CoreMloMain = (props) => {
    return (
        <div className={"subMain"}>
            <div className={"subContent"}>
                <h2 className={"subHeader"}>Core Major Learning Outcomes</h2>
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>Professional Communication and Development</th>
                            <th>Programming and Problem Solving</th>
                            <th>Mathematics for Computing</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST300")}>CST 300: Major ProSeminar
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST231")}>CST 231: Problem Solving
                                            and Programming C++
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST238")}>CST 238: Introduction to
                                            Data Structures
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("MATH170")}>MATH 170: Discrete
                                            Mathematics
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("MATH130")}>MATH 130: Pre Calculus
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST370")}>CST 370: Design and
                                            Analysis of Algorithms
                                        </button>
                                    </li>
                                </ul>
                                Pending:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("MATH150")}>MATH 150: Calculus I
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("MATH151")}>MATH 151: Calculus II
                                        </button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>Knowledge of Computers and Networks</th>
                            <th>Interaction Design</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST237")}>CST 237: Intro to Computer
                                            Architecture
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST205")}>CST 205: Multimedia Design
                                            and Programming
                                        </button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default CoreMloMain;