import React from 'react';

const CST234 = () => {
    return (
        <div>
            <h2>CST 234: Intro to Operating Systems</h2>
            <p>
                Introduces modern operating systems such as Unix and topics such as process management (creation,
                synchronization, and communication); processor scheduling; deadlock prevention, avoidance, and recovery;
                main-memory management; virtual memory management (swapping, paging, segmentation and page-replacement
                algorithms); control of disks and other input/output devices; file-system structure and implementation;
                and protection and security.
            </p>
        </div>
    )
};

export default CST234;