import React from 'react';

const MATH170 = () => {
    return (
        <div>
            <h2>MATH 170: Discrete Mathematics</h2>
            <p>
                Includes sets and sequences, elementary logic, relations, induction, counting principles, discrete
                probability, Boolean algebra, logic networks, matrices, graph theory, and trees. Applies these topics to
                real life and branches of science, particularly computer science.
            </p>
        </div>
    )
};

export default MATH170;