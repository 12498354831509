import React from 'react';

const FinalMloMain = (props) => {
    return (
        <div className={"subMain"}>
            <div className={"subContent"}>
                <h2 className={"subHeader"}>Final Major Learning Outcomes</h2>
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>Service to the Community</th>
                            <th>CST Elective</th>
                            <th>Capstone Project</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST361")}>CST 361S: Service
                                            Learning
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST234")}>CST 234: Intro to Operating
                                            Systems
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST311")}>CST 311: Intro to Computer
                                            Networks
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST363")}>CST 363: Introduction to
                                            Database Systems
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST325")}>CST 325: Graphics
                                            Programming
                                        </button>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                Fulfilled by:
                                <ul>
                                    <li>
                                        <button onClick={() => props.setChildPage("CST499")}>CST 499: Computer Science
                                            Capstone
                                        </button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default FinalMloMain;