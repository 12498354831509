import React from 'react';

const MATH130 = () => {
    return (
        <div>
            <h2>MATH 130: Pre Calculus</h2>
            <p>
                Functions and graphs, including polynomial, rational, exponential, logarithmic, and trigonometric
                functions. Applications, data analysis, mathematical modeling, and analytic trigonometry.
            </p>
        </div>
    )
};

export default MATH130;