import React from 'react';

const CST237 = () => {
    return (
        <div>
            <h2>CST 237: Intro to Computer Architecture</h2>
            <p>
                This course introduces computer architectures and computer systems management. Includes data
                representation, memory, registers, and internals of common computers and system interfaces.
            </p>
        </div>
    )
};

export default CST237;