import React from 'react';

const CST361 = () => {
    return (
        <div>
            <h2>CST 361S: Service Learning</h2>
            <p>
                A service learning course in which students apply computer literacy, multimedia design, and technology
                to assist schools, nonprofit organizations, and community agencies. The theme for the course is
                "Bridging the Digital Divide." Note: students must participate in required service placements with hours
                to be arranged. Students must be able to create and manage a web site.
            </p>
            <div>
                <h2>Final Deliverable: Vetted Ticketing Solution for a Non Profit</h2>
                <div>
                    <iframe
                        src="https://docs.google.com/presentation/d/e/2PACX-1vRxCF2pas4uPQUU0kf5Myr446SSQvaXV0sNEmVF-JEd8e1HVQ3u-M4rhA9VfBQbPf5Ws-sUwJ5_B-t-/embed?start=true&loop=true&delayms=3000"
                        frameBorder="0" width="900" height="500" allowFullScreen="true" mozallowfullscreen="true"
                        webkitallowfullscreen="true"></iframe>
                </div>
            </div>
        </div>
    )
};

export default CST361;